

































































































































import { computed, defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { useToast } from 'vue-toastification/composition'
import utils from '@/shared/mixins/utils'
import query from '@/shared/queries/normQueries'
import variables from '@/shared/variables'
import CreateNorm from '@/components/norms/CreateNorm.vue'
import NormOperations from '@/components/norms/NormOperations.vue'
import CopyNormDialog from '@/components/norms/CopyNormDialog.vue'
import { UserRole } from '@/shared/enum/general-enum'
import normsData from '@/assets/data/norms-data.json'
import { NormType } from '@/shared/enum/norms-enum'
import { NormDTO } from '@/dto/backend-response/normsDTO'

export default defineComponent({
    components: { Table, CreateNorm, NormOperations, CopyNormDialog, },
    props: {
        idfilter: {
            type: Number,
        },
    },
    setup() {
        const normList = ref([])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { menuOpen } = useState(['menuOpen'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const toast = useToast()
        const loading = ref(false)
        const infomationOp = ref(null)
        const { role } = useState(['role'])
        const displayCreateNorm = ref(false)
        const visiblenormDetails = ref(false)
        const normDetails = ref(null)
        const visibleLeft = ref(false)
        const tableCmp = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const copyNormVisible = ref(false)
        const copyNormLoading = ref(false)
        const normFilters = ref([])
        // get current organization id
        const totalPages = ref(0)
        const loadingSpinnerActive = computed(
            () => loading.value || copyNormLoading.value
        )
        // set table headers
        const setTableHeaders = (language: string) => {
            tableHeaders.value = [
                {
                    header: t('NORMS_TABLE_COLUMN_NO', language),
                    sort: true,
                    fieldName: 'no',
                    style: 'min-width: 150px;',
                },
                {
                    header: t('NORMS_TABLE_COLUMN_NAME', language),
                    sort: true,
                    fieldName: 'name',
                },
                {
                    header: t('NORMS_TABLE_COLUMN_DESCRIPTION', language),
                    sort: true,
                    fieldName: 'description',
                    limit: 300,
                },
                {
                    header: t('NORMS_TABLE_COLUMN_VERSION', language),
                    sort: true,
                    fieldName: 'version',
                },
                {
                    header: t('NORMS_TABLE_COLUMN_PUBLICATION_DATE', language),
                    sort: true,
                    fieldName: 'publicationDate',
                    type: 'Date',
                },
                {
                    header: t('NORMS_TABLE_COLUMN_TYPE', language),
                    sort: true,
                    fieldName: 'type',
                },
            ]
        }

        // enable create norm for `Admin`, `Perium admin` and `Developer admin`
        const enableCreateNormButton = computed(() => {
            return (
                role.value === UserRole.ADMIN ||
                role.value === UserRole.PERIUM_ADMIN ||
                role.value === UserRole.DEVELOPER_ADMIN
            )
        })

        const enableCopyNormButton = computed(() => {
            return (
                role.value === UserRole.PERIUM_ADMIN ||
                role.value === UserRole.DEVELOPER_ADMIN
            )
        })

        // updating normList array with additional fields and setting description content
        const toTableFormat = (tempResultData: Array<NormDTO>): Array<NormDTO & {no: number; type: string}> => {
            const extendedNorms = tempResultData.map((norm, index) => ({
                    ...norm,
                    no: utils.padLeft('000', index + 1),
                    type: t(
                        utils.keyToName(normsData.NORM_TYPE, norm.normType),
                        language.value
                    ),
                }))
                return extendedNorms
            
        }

        // get all norms
        const getNorms = async () => {
            loading.value = true
            const getNormsQuery = `
                query{
                    norms(filter: {isSelectedNorms:true}){
                        items{
                            ${query.NORMS}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getNormsQuery)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving norms')
            }
            if (result) {
                const norms = result.data.norms.items
                norms.sort((a, b) => (a.id > b.id ? 1 : -1))
                totalPages.value = 1
                filteredRecordCount.value = result.data.norms.items.length
                normList.value = toTableFormat(norms)
            }
        }

        // get norm by id
        const getNorm = async (id: number) => {
            normDetails.value = null
            displayCreateNorm.value = false
            const normQuery = `
                query {
                    norm(id: ${id}) {
                        ${query.NORM_DETAILS}
                        controlReferences {
                            referenceType
                            fromType
                            fromID
                            canReorder
                            references {
                                label
                                toID
                                toType
                                sortPosition
                            }
                        }
                    }
                }
            `
            const result = await apolloClient.getGraphqlData(normQuery)
            if (result) {
                normDetails.value = result.data.norm
                normDetails.value.no = normList.value.find(
                    (val) => val.id === normDetails.value.id
                ).no
                normDetails.value.lastRecordNo =
                    tableCmp.value && utils.getLastRecord(tableCmp.value.copyOfTableData)
                normDetails.value.firstRecordNo =
                    tableCmp.value && tableCmp.value.copyOfTableData[0].no
            }
        }

        // go to norm record
        const goToRecord = async (params: { typeOfButton: string; no: string }) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `normList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.typeOfButton,
                    params.no,
                    tableCmp.value
                )
                // use the id of the record to get next/previous details
                await getNorm(record.id)
            }
        }

        // save while table list is sorted
        const sortableListSave = () => {
            // if the table is sorted (`sortedField` has a value)
            if (tableCmp.value.sortedField) {
                // Based on the sorted type(asc/desc), sort the refreshed list after saving
                if (tableCmp.value.sortToggle === 'up') {
                    normList.value.sort((a, b) =>
                        a[tableCmp.value.sortedField] < b[tableCmp.value.sortedField] ? 1 : -1
                    )
                } else {
                    normList.value.sort((a, b) =>
                        b[tableCmp.value.sortedField] < a[tableCmp.value.sortedField] ? 1 : -1
                    )
                }
            }
        }
        // updating norms data
        const saveNormData = async (params: {
            id: number;
            formData;
            createdBy: boolean;
            type: string;
            no: string;
        }) => {
            const normFormData = params.formData
            // mutation query for other users
            const mutationQuery = `mutation ($input: NormInput!) {
                    updateNorm(id: ${params.id}, input: $input) {
                        status
                        error
                    }
                }`
            // update input for the entities which are created by user
            const input = {
                name: normFormData.name,
                displayName: normFormData.displayName,
                description: normFormData.description,
                longDescription: normFormData.longDescription,
                version: normFormData.version,
                normType: normFormData.normType,
                normTypeOther: normFormData.normTypeOther,
                publicationDate: normFormData.publicationDate,
                owner: normFormData.owner,
                documentationUrl: normFormData.documentationUrl,
            }

            // update data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('NORM_UPDATE_ERROR_MESSAGE', language.value))
                throw err
            }
            if (result.data.updateNorm && result.data.updateNorm.status) {
                normList.value = []
                await getNorms()
                sortableListSave()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.type === 'save-next') {
                    await goToRecord({ no: params.no, typeOfButton: 'next' })
                } else {
                    await getNorm(params.id)
                }

                toast.success(t('NORM_UPDATE_SUCESS_MESSAGE', language.value))
            } else {
                toast.error(t('NORM_UPDATE_ERROR_MESSAGE', language.value))
            }
        }

        //  create new norm save
        const createNorm = async (params) => {
            const normFormData = params.formData

            const mutationQuery = `mutation ($input: NormInput!) {
                        createNorm(input: $input) {
                            status
                            error
                        }
                    }`

            const input = {
                name: normFormData.name,
                displayName: normFormData.displayName,
                description: normFormData.description,
                longDescription: normFormData.longDescription,
                version: normFormData.version,
                normType: normFormData.normType,
                normTypeOther: normFormData.normTypeOther,
                // by default date is set to today date, but the date should be empty if user hasn't select 'Other' as norm type
                publicationDate:
                    normFormData.normType === NormType.OTHER ? normFormData.publicationDate : null,
                owner: normFormData.owner,
                documentationUrl : normFormData.documentationUrl,
            }

            // create norm api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('NORM_CREATE_ERROR_MESSAGE', language.value))
                throw Error('Error while creating norm')
            }
            if (result.data.createNorm && result.data.createNorm.status) {
                toast.success(t('NORM_CREATE_SUCESS_MESSAGE', language.value))
                normList.value = []
                await getNorms()
                displayCreateNorm.value = false
            } else {
                toast.error(t('NORM_CREATE_ERROR_MESSAGE', language.value))
            }
        }



        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        //  display therat details sidebar
        const openNormDetailsPopup = async (id) => {
            visiblenormDetails.value = true
            await getNorm(id)
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count) => {
            filterCount.value = count
        }

        // set searched records count
        const searchCount = (count) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }
        // update table records count
        const updateRecordCount = (recordsCount) => {
            filteredRecordCount.value = recordsCount
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close view details entity if it's open
                    if (visiblenormDetails.value) {
                        visiblenormDetails.value = false
                    }
                    // close create new entity if it's open
                    if (displayCreateNorm.value) {
                        displayCreateNorm.value = false
                    }
                }
            })
        }

        const openCopyNormDialog = () => {
            copyNormVisible.value = true
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            (newValue: string) => {
                setTableHeaders(newValue)
            },
            {
                immediate: true,
            }
        )

        onMounted(async () => {
            closeSidebarEsc()
            getNorms()
        })

        return {
            normList,
            getNorm,
            t,
            language,
            tableHeaders,
            saveNormData,
            loading,
            infomationOp,
            toggleInformation,
            displayCreateNorm,
            getNorms,
            role,
            variables,
            openNormDetailsPopup,
            visiblenormDetails,
            normDetails,
            goToRecord,
            utils,
            menuOpen,
            visibleLeft,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            tableCmp,
            createNorm,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            normFilters,
            UserRole,
            totalPages,
            enableCreateNormButton,
            copyNormVisible,
            copyNormLoading,
            openCopyNormDialog,
            enableCopyNormButton,
            loadingSpinnerActive,
        }
    },
})
