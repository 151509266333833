



































import { defineComponent, ref } from '@vue/composition-api'
import { useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import NormDetails from './NormDetails.vue'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    components: { NormDetails },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const infomationOp = ref(null)

        // open information overlay panel
        const toggleInformation = (event: MouseEvent) => {
            utils.findMousePointerLocationOP(event)
            infomationOp.value.toggle(event)
        }

        // close create new popup
        const closeCreateNew = () => {
            emit('close-create-new')
        }

        // save create new norm data
        const saveNorm = (params) => {
            emit('save-norm', params)
        }

        return {
            t,
            language,
            closeCreateNew,
            saveNorm,
            infomationOp,
            toggleInformation,
        }
    },
})
