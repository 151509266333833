

































































import utils from '@/shared/mixins/utils'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import NormDetails from './NormDetails.vue'
import removeMd from 'remove-markdown'
import variables from '@/shared/variables'
import EntityOrderEditor from '@/shared/components/EntityOrderEditor.vue'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
            required: true,
        },
    },
    components: {
        NormDetails,
        EntityOrderEditor,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        
        const copyOfEntityDetails = ref({})
        const TabviewCmp = ref(null)
        const nameInitals = ref('')

        // go to record (previous/ next)
        const goToRecord = (params) => {
            emit('go-to-record', params)
        }

        const reloadNorm = () => {
            emit('go-to-record', {
                no: props.entityDetails.no,
                typeOfButton: 'same',
            })
        }

        // save edited data
        const saveNorm = (params) => {
            emit('save-norm', params)
        }

        // format data before display
        const setFormattedDetails = () => {
            // owner
            const fullNameOwner =
                (props.entityDetails.created_by && props.entityDetails.created_by.firstName) +
                ' ' +
                (props.entityDetails.created_by && props.entityDetails.created_by.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)
        }

        onMounted(() => {
            setFormattedDetails()
        })

        return {
            t,
            language,
            utils,
            copyOfEntityDetails,
            goToRecord,
            reloadNorm,
            saveNorm,
            TabviewCmp,
            removeMd,
            nameInitals,
            variables,
        }
    },
})
