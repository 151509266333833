































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import Dropdown from 'primevue/dropdown/Dropdown'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import ToggleSwitch from '@/shared/components/ToggleSwitch.vue'
import InfoIcon
 from '@/shared/components/InfoIcon.vue'
import utils from '@/shared/mixins/utils'
import { gql } from '@apollo/client/core'
import { useToast } from 'vue-toastification/composition'

import { captureException } from '@sentry/vue'
// get all norms
const getCopyableNormOptions = async (): Promise<Array<{id: number; displayName: string}>> => {
    const result = await apolloClient.apolloClient.query({
        query: gql`
            query {
                norms(filter: { onlyMasterNorms : true }){
                    items {
                        displayName
                        id
                    }
                }
            }
    `})
    if (!result.data?.norms?.items) {
        throw Error(`Error while fetching copyable norms.`)
    }
    return result.data.norms.items.filter(norm => norm?.displayName).sort(
        (a: {displayName: string}, b: {displayName: string}) => a.displayName.localeCompare(b.displayName)
    )
}

export interface CopyNormFormData {
    SOURCE_NORM_ID: number | null;
}

const defaultFormData = (): CopyNormFormData => ({
    SOURCE_NORM_ID: null
})

const initializeErrors = () => ({
    SOURCE_NORM_ID: { missingValue: false }
})

const validateForm = (
    formData: CopyNormFormData
) => {
    const errors = initializeErrors()
    errors.SOURCE_NORM_ID.missingValue = !formData.SOURCE_NORM_ID
    return errors
}

const copyNorm = async (formData: CopyNormFormData) => {
    const result = await apolloClient.apolloClient.mutate({
        mutation: gql`mutation ($sourceNormId: ID!) {
                copyNorm(sourceNormId: $sourceNormId) {
                    norm { id }
                    status
                    error
                    errorCode {
                        name
                        value
                    }
                }
            }`,
            variables: {
                sourceNormId: formData.SOURCE_NORM_ID
            }
    })
    if (!result?.data?.copyNorm?.status) {
        throw Error(`Backend error while copying norm: ${result?.data?.copyNorm?.errorCode?.value}`)
    }
    return result.data.copyNorm.norm.id
}

export default defineComponent({
    emits: ['norm-created', 'update:visible', 'update:loading'],
    props: {
        visible: {
            default: false,
            type: Boolean,
        },
        loading: {
            default: false,
            type: Boolean,
        },
    },
    components: { Dropdown, ToggleSwitch, InfoIcon},
    setup(props, {emit}) {
        const { t } = useI18n();
        const { language } = useState(['language']);
        const sourceNormOptions = ref([])
        const formData = ref(defaultFormData())
        const formErrors = ref(initializeErrors())
        const toast = useToast()
        onMounted(
            async () => {
                try  {
                    emit('update:loading', true)
                    sourceNormOptions.value = await getCopyableNormOptions()
                }
                catch (err) {
                    captureException(err)
                    toast(t("LOADING_ERROR_MESSAGE", language))
                }
                finally {
                    emit('update:loading', false)
                }
            }
        )
        const clearForm = () => {
            formData.value = defaultFormData()
            formErrors.value = initializeErrors()
        }
        const close = () => {
            clearForm()
            emit('update:visible', false)
            if (props.loading) {
                emit('update:loading', false)
            }
        }
        const submit = async () => {
            formErrors.value = validateForm(formData.value)
            if (utils.noErrorsSet(formErrors.value)) {
                try {
                    emit('update:loading', true)
                    const newNormId = await copyNorm(formData.value)
                    toast.success(t('NORM_CREATE_SUCESS_MESSAGE', language.value))
                    emit('norm-created', newNormId)
                    close()
                }
                catch {
                    toast.error(t('NORM_CREATE_ERROR_MESSAGE', language.value))
                }
                finally {
                    emit('update:loading', false)
                }
            }
        }
        return {
            confirm,
            t,
            language,
            sourceNormOptions,
            formData,
            submit,
            emit,
            formErrors,
            close,
        };
    },
})
