























































































import utils from '@/shared/mixins/utils'
import { defineComponent, ref, nextTick, Ref, computed } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import { PropType } from 'vue';
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { gql } from '@apollo/client/core'
import { useToast } from 'vue-toastification/composition'


interface EntityReference {
    toType: string;
    toID: number;
    label: string;
    sortPosition: number;
}

interface EntityReferences {
    fromType: string;
    fromID: number;
    referenceType: string;
    canReorder: boolean;
    references: Array<EntityReference>;
}

const saveUpdatedReferences = async (
    referenceData: EntityReferences,
    updatedReferences: Array<EntityReference>,
) => {
    const result = await apolloClient.apolloClient.mutate({
        mutation: gql`mutation ($input: UpdatedEntityReferences!) {
                updateEntityReferences(input: $input) {
                    status
                }
            }`,
            variables: {
                input: {
                    fromID: referenceData.fromID,
                    fromType: referenceData.fromType,
                    referenceType: referenceData.referenceType,
                    updatedReferences: updatedReferences.map(
                        ({toType, toID, sortPosition}) => ({toType, toID, sortPosition})
                    ),
                }
            }
    })
    if (!result?.data?.updateEntityReferences?.status) {
        throw Error(`Backend error while updating references: ${result?.data?.updateEntityReferences?.errorCode?.value}`)
    }
    return result.data.updateEntityReferences
}

export default defineComponent({
    emits: [
        /**
         * Emitted when the component wants the references to be re-loaded.
         */
        'saved',
        'save-failed',
    ],
    props: {
        referenceData: {
            type: Object as PropType<EntityReferences>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])

        const toast = useToast()

        const container: Ref<HTMLElement> = ref(null)
        const references: Ref<EntityReference[]> = ref([...props.referenceData.references])

        const isLoadingReferences = ref(false)
        const selection: Ref<EntityReference[]> = ref([])
        const moveButtonsEnabled = computed(
            () => selection.value.length > 0
        )
        // Always show the move targets sorted on label
        const moveTargetOptions = ref(utils.recordsSortedOnField(
            props.referenceData.references,
            'label',
            true,
        ))
        const moveTarget: Ref<EntityReference> = ref(null)

        const reordering = ref(false)
        const startReordering = () => {
            reordering.value = true
        }
        const cancelReordering = () => {
            reordering.value = false
            references.value = [...props.referenceData.references]
            selection.value = []
        }

        const saveOrder = async () => {
            try {
                isLoadingReferences.value = true
                await saveUpdatedReferences(
                    props.referenceData,
                    references.value,
                )
                toast.success(t("NORM_CONTROL_NUMBER_ORDER_SUCCESS_TOAST", language.value))
                emit('saved')
                reordering.value = false
                selection.value = []
            } catch {
                toast.error(t("NORM_CONTROL_NUMBER_ORDER_FAILED_TOAST", language.value))
                emit('save-failed')
            }
            finally {
                isLoadingReferences.value = false
            }

        }
        const scrollToRow = (rowID: number) => {
            const rowElement: HTMLElement | null = container.value?.querySelector(`a[data-reference-id="${rowID}"]`);
            if (rowElement) {
                rowElement.scrollIntoView({
                    block: 'center',
                })
            }
        }

        /**
         * Put selection after a specific position.
         * 
         * All elements in the selection are removed from data. Then, 
         * 
         * @param data Move selection to specified position by changing the sortPosition field
         * @param selectedIDs Array containing ID's of the rows that should be re-ordered
         * @param placeAfterPosition Position after which the selection will be placed, in data
         * @param sortSelectionOn Field name on which to sort the selection if multiple are selected,
         *                        with a flag to indicate if it is a string or a number.
         */
        const putSelectionAfterPosition = (
            data: Array<EntityReference>,
            selectedIDs: Array<number>,
            placeAfterPosition: number,
            sortSelectionOn: {
                field: keyof EntityReference;
                naturalSort: boolean;
            } = {
                field: 'sortPosition',
                naturalSort: false
            }
        ): Array<EntityReference>  => {
            const selected = utils.recordsSortedOnField(
                data.filter(row => selectedIDs.includes(row.toID)),
                sortSelectionOn.field,
                sortSelectionOn.naturalSort,
            )
            const unselected = data.filter(row => !selectedIDs.includes(row.toID))
            const reordered = [
                ...unselected.filter(row => row.sortPosition <= placeAfterPosition),
                ...selected,
                ...unselected.filter(row => row.sortPosition > placeAfterPosition)
            ].map(
                // Re-assign sortPosition based on index in the reordered array, 
                // starting at 1.
                (row, index) => ({...row, sortPosition: index + 1})
            )
            return reordered
        }

        const moveSelection = (mode: 'top' | 'bottom' | 'group' | 'after' | 'up' | 'down' | 'reorder' = 'top') => {
            if (!selection.value.length) {
                return;
            }
            const selectedIDs = selection.value.map(row => row.toID)
            const selectionPosition = references.value.find(row => selectedIDs.includes(row.toID))?.sortPosition ?? 0
            const beforeSelection = Math.max(0, selectionPosition - 1)
            const afterSelection = Math.max(...selection.value.map(row => row.sortPosition)) + 1
            const selectedTargetPosition = references.value.find(
                row => row.toID === moveTarget?.value?.toID
            )?.sortPosition ?? selectionPosition
            const targetPosition: {[mode: string]: number} = {
                'top': 0,
                'bottom': references.value.length,
                'up': beforeSelection - 1,
                'down': afterSelection,
                'after': selectedTargetPosition,
                'reorder': beforeSelection,
            }
            references.value = putSelectionAfterPosition(
                references.value, 
                selectedIDs,
                targetPosition[mode],
                (
                    mode === 'reorder'
                    ? { field: 'label', naturalSort: true }
                    : { field: 'sortPosition', naturalSort: false }
                )
            )

            selection.value = references.value.filter(row => selectedIDs.includes(row.toID))
            
            nextTick(
                () => scrollToRow(selection.value[0].toID)
            )

        }

        return {
            t,
            language,
            utils,
            reordering,
            startReordering,
            cancelReordering,
            saveOrder,
            references,
            selection,
            moveSelection,
            moveTarget,
            moveTargetOptions,
            moveButtonsEnabled,
            container,
            isLoadingReferences,
            
        }
    },
})
